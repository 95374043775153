import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { IUsers, IProfileUpdate } from './types'

export const useApiGetUser = () => {
  // query
  const query = useQuery<IUsers[]>({
    queryKey: ['getUser'],
    queryFn: () => fetchAPI<IUsers[]>('/users'),
    gcTime: 0,
    staleTime: 0,
  })

  return {
    ...query,
  }
}

export const useApiGetUserById = (userId: string) => {
  const queryClient = useQueryClient()
  // query
  const query = useQuery<IUsers>({
    queryKey: ['getUser', userId],
    queryFn: () => fetchAPI<IUsers>(`/users/${userId}`),
  })
  const updateUser = useMutation({
    mutationFn: (body: IProfileUpdate) => {
      const url = `/users/${userId}`
      return fetchAPI(url, {
        method: 'PUT',
        body,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getUser'] })
      queryClient.invalidateQueries({ queryKey: ['getUser', userId] })
    },
  })
  return {
    ...query,
    updateUser,
  }
}

export const useApiGetUserByCompanyId = (companyId: string) => {
  return useQuery<IUsers[]>({
    queryKey: ['GetUserByCompanyId', companyId],
    queryFn: () =>
      fetchAPI<IUsers[]>(`/companies/${companyId}/users`).then((response) => {
        return response.sort((a, b) => {
          const nameA = a.name.trim().length > 1 ? a.name : a.email
          const nameB = b.name.trim().length > 1 ? b.name : b.email
          return nameA.localeCompare(nameB)
        })
      }),
    gcTime: 0,
    staleTime: 0,
  })
}
